<template>
  <div class="bg-white shadow rounded-md sm:px-5 p-4 mt-4 sm:m-0">
    <div>
      <div class="flex justify-between border-b py-2 cursor-pointer">
        <div class="flex justify-between w-full relative">
          <div class="flex">
            <div class="me-5">
              <svg
                class="bank-document-icon"
                fill="none"
                height="40"
                viewBox="0 0 40 40"
                width="40"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20" cy="20" fill="#00B8EE" opacity="0.1" r="20" />
                <path
                  d="M17 20H23H17ZM17 24H23H17ZM25 29H15C14.4696 29 13.9609 28.7893 13.5858 28.4142C13.2107 28.0391 13 27.5304 13 27V13C13 12.4696 13.2107 11.9609 13.5858 11.5858C13.9609 11.2107 14.4696 11 15 11H20.586C20.8512 11.0001 21.1055 11.1055 21.293 11.293L26.707 16.707C26.8946 16.8945 26.9999 17.1488 27 17.414V27C27 27.5304 26.7893 28.0391 26.4142 28.4142C26.0391 28.7893 25.5304 29 25 29Z"
                  stroke="#00B8EE"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
              </svg>
            </div>
            <div class="text-sm font-normal">
              <div class="text-sm font-normal">
                <div>
                  {{ account.account_holdername }}
                </div>
                <div class="text-gray-500">
                  {{ account.email }}
                </div>
              </div>
            </div>
          </div>
          <div v-click-outside="hide">
            <div
              class="hover:bg-gray-100 rounded-full w-10 h-10 text-center p-2"
              @click="editBank"
            >
              <svg
                class="m-auto"
                fill="none"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 5V5.01V5ZM12 12V12.01V12ZM12 19V19.01V19ZM12 6C11.7348 6 11.4804 5.89464 11.2929 5.70711C11.1054 5.51957 11 5.26522 11 5C11 4.73478 11.1054 4.48043 11.2929 4.29289C11.4804 4.10536 11.7348 4 12 4C12.2652 4 12.5196 4.10536 12.7071 4.29289C12.8946 4.48043 13 4.73478 13 5C13 5.26522 12.8946 5.51957 12.7071 5.70711C12.5196 5.89464 12.2652 6 12 6ZM12 13C11.7348 13 11.4804 12.8946 11.2929 12.7071C11.1054 12.5196 11 12.2652 11 12C11 11.7348 11.1054 11.4804 11.2929 11.2929C11.4804 11.1054 11.7348 11 12 11C12.2652 11 12.5196 11.1054 12.7071 11.2929C12.8946 11.4804 13 11.7348 13 12C13 12.2652 12.8946 12.5196 12.7071 12.7071C12.5196 12.8946 12.2652 13 12 13ZM12 20C11.7348 20 11.4804 19.8946 11.2929 19.7071C11.1054 19.5196 11 19.2652 11 19C11 18.7348 11.1054 18.4804 11.2929 18.2929C11.4804 18.1054 11.7348 18 12 18C12.2652 18 12.5196 18.1054 12.7071 18.2929C12.8946 18.4804 13 18.7348 13 19C13 19.2652 12.8946 19.5196 12.7071 19.7071C12.5196 19.8946 12.2652 20 12 20Z"
                  stroke="#98A7B9"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
              </svg>
            </div>
            <div
              v-show="showMenu"
              id="edit-bank"
              class="origin-top absolute float-start mt-2 w-1/3 rounded-md shadow-lg right-3"
            >
              <div class="rounded-md bg-white shadow-xs">
                <div
                  aria-labelledby="options-menu"
                  aria-orientation="vertical"
                  class="py-1"
                  role="menu"
                >
                  <div
                    class="block cursor-pointer px-4 py-2 text-sm leading-5 text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                    role="menuitem"
                    @click="(showMenu = false), $emit('edit', account)"
                  >
                    {{ $t("Edit") }}
                  </div>
                  <div
                    class="block cursor-pointer px-4 py-2 text-sm leading-5 text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                    role="menuitem"
                    @click="(showDeleteModal = true), (showMenu = false)"
                  >
                    {{ $t("Delete") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-sm">
        <div class="sm:grid sm:grid-cols-7 sm:grid-gap-2">
          <div class="col-span-3 py-2 font-normal">
            {{ $t("account-holder-name") }}
          </div>
          <div class="col-span-3 py-2 font-light">
            {{ account.account_holdername }}
          </div>
          <div class="col-span-1 py-2"></div>
        </div>
        <div class="sm:grid sm:grid-cols-7 sm:grid-gap-2">
          <div class="col-span-3 py-2 font-normal">
            {{ $t("bank-name") }}
          </div>
          <div class="col-span-3 py-2 font-light">
            {{ account.bank_name }}
          </div>
          <div class="col-span-1 py-2"></div>
        </div>
        <div class="sm:grid sm:grid-cols-7 sm:grid-gap-2">
          <div class="col-span-3 py-2 font-normal">
            {{ $t("bank-city-14fbc76abc216e3ed8992563e764d413") }}
          </div>
          <div class="col-span-3 py-2 font-light">
            {{ account.bank_city }}
          </div>
          <div class="col-span-1 py-2 font-light"></div>
        </div>
        <div class="sm:grid sm:grid-cols-7 sm:grid-gap-2">
          <div class="col-span-3 py-2 font-normal">
            {{ $t("Currency") }}
          </div>
          <div class="col-span-3 py-2 font-light">
            {{ account.currency }}
          </div>
          <div class="col-span-1 py-2 font-light"></div>
        </div>
        <div class="sm:grid sm:grid-cols-7 sm:grid-gap-2">
          <div class="col-span-3 py-2 font-normal">
            {{ $t("bank-account-number") }}
          </div>
          <div class="col-span-3 py-2 font-light">
            {{ account.bank_account_number }}
          </div>
          <div class="col-span-1 py-2 font-light">
            <a
              class="font-normal sm:float-right cursor-pointer text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
              @click="copyToClipboard(account.bank_account_number)"
            >
              {{ $t("copy") }}
            </a>
          </div>
        </div>
        <div class="sm:grid sm:grid-cols-7 sm:grid-gap-2">
          <div class="col-span-3 py-2 font-normal">
            {{ $t("IBAN") }}
          </div>
          <div class="col-span-3 py-2 font-light">
            {{ account.iban }}
          </div>
          <div class="col-span-1 py-2 font-light">
            <a
              class="font-normal sm:float-right cursor-pointer text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
              @click="copyToClipboard(account.iban)"
            >
              {{ $t("copy") }}
            </a>
          </div>
        </div>
        <div
          v-if="account.sort_code"
          class="sm:grid sm:grid-cols-7 sm:grid-gap-2"
        >
          <div class="col-span-3 py-2 font-normal">
            {{ $t("sort-code") }}
          </div>
          <div class="col-span-3 py-2 font-light">
            {{ account.sort_code }}
          </div>
          <div
            v-if="account.sort_code.length > 0"
            class="col-span-1 py-2 font-light"
          >
            <a
              class="font-normal sm:float-right cursor-pointer text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
              @click="copyToClipboard(account.sort_code)"
            >
              {{ $t("copy") }}
            </a>
          </div>
        </div>
        <div class="sm:grid sm:grid-cols-7 sm:grid-gap-2">
          <div class="col-span-3 py-2 font-normal">
            {{ $t("swift-code") }}
          </div>
          <div class="col-span-3 py-2 font-light">
            {{ account.swift_code }}
          </div>
          <div class="col-span-1 py-2 font-light">
            <a
              class="font-normal sm:float-right cursor-pointer text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
              @click="copyToClipboard(account.swift_code)"
            >
              {{ $t("copy") }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <!--              Modal for deleting account-->
    <div
      v-if="showDeleteModal"
      class="bg-white shadow sm:rounded-lg w-1/2 left-1/4 fixed md:w-1/3 top-1/3 md:left-1/3 z-10"
    >
      <div class="px-4 py-5 sm:p-6">
        <h3
          class="text-center sm:text-left text-lg leading-6 font-normal text-gray-900"
        >
          {{ $t("delete-your-bank-account") }}
        </h3>
        <div class="mt-2 max-w-xl text-sm leading-5 text-gray-500">
          <p class="text-center sm:text-left">
            {{ $t("are-you-sure-you-want-to-delete-bank-account") }}
          </p>
        </div>
        <div class="flex flex-col mt-5 sm:flex-row">
          <div
            class="mr-0 flex justify-center sm:justify-start sm:mr-5"
            @click="deleteBankAccount(account.id)"
          >
            <button
              class="inline-flex items-center justify-center px-4 py-2 border border-transparent font-normal rounded-md text-red-700 bg-red-100 hover:bg-red-50 focus:outline-none focus:border-red-300 focus:shadow-outline-red active:bg-red-200 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              type="button"
            >
              {{ $t("delete-account") }}
            </button>
          </div>
          <div
            class="mt-3 sm:mt-0 flex justify-center sm:justify-start"
            @click="(selectedAccountId = ''), (showDeleteModal = false)"
          >
            <button
              class="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
              type="button"
            >
              {{ $t("Cancel") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ClickOutside from "vue-click-outside";

export default {
  name: "BankDetailsCard",
  data() {
    return {
      showMenu: false,
      showDeleteModal: false,
    };
  },
  props: {
    account: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    deleteBankAccount(id) {
      this.$store
        .dispatch("delete_bank_account", id)
        .then(() => {
          this.$store.dispatch("bank_accounts");
          this.showDeleteModal = false;
        })
        .catch((err) => {
          this.$notify({
            group: "foo",
            text: `${Object.entries(err.data)[0][1]}`,
            type: "warn",
          });
          this.showDeleteModal = false;
        });
    },
    editBank() {
      this.showMenu = !this.showMenu;
      let lang = localStorage.getItem("lang") || "en";
      if (lang === "ar" || lang === "fa") {
        document.getElementById("edit-bank").classList.remove("right-3");
        document.getElementById("edit-bank").classList.add("left-3");
      } else {
        console.log(document.getElementById("edit-bank"));
        document.getElementById("edit-bank").classList.remove("left-3");
        document.getElementById("edit-bank").classList.add("right-3");
      }
    },
    copyToClipboard(data) {
      const t = this.$t.bind(this);
      this.$copyText(data);
      this.$notify({
        group: "foo",
        text: t("Successfully copied to clipboard!"),
      });
    },
    hide() {
      this.showMenu = false;
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>