<template>
  <div class="bank-detail-vue">
    <Header>
      <template v-slot:title>
        {{ $t("bank-details") }}
      </template>
      <template v-slot:buttons>
        <span
          v-if="!show_new_bank_account"
          class="inline-flex rounded-md shadow-sm"
        >
          <button
            class="relative mr-3 bg-main-button-color inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-normal rounded-md text-white focus:outline-none focus:shadow-outline-indigo"
            type="reset"
            @click="showBankAccount"
          >
            <svg
              class="mr-2"
              fill="none"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 5V19"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
              <path
                d="M5 12H19"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
            </svg>
            {{ $t("add-bank-details") }}
          </button>
        </span>
      </template>
    </Header>
    <div class="min-h-screen relative max-w-7xl m-auto">
      <!--List of cards-->
      <need-trading-account-alert
        v-if="get_bank_accounts.length === 0"
        :title="$t('you-dont-have-any-bank-account-details')"
      ></need-trading-account-alert>
      <div class="sm:grid sm:grid-cols-2 sm: gap-8">
        <bank-details-card
          v-for="account in get_bank_accounts"
          :key="account.id"
          :account="account"
          @edit="editBankDetails"
        ></bank-details-card>
      </div>

      <ValidationObserver v-slot="{ handleSubmit, reset }">
        <form
          @submit.prevent="handleSubmit(addBankAccount)"
          @reset.prevent="reset"
        >
          <!--             Add new bank account-->
          <div
            v-if="show_new_bank_account"
            class="fixed inset-0 overflow-hidden add-bank-account"
          >
            <div class="absolute inset-0 overflow-hidden">
              <div class="fixed inset-0 transition-opacity">
                <div class="absolute inset-0 bg-black opacity-50"></div>
              </div>
              <section
                class="absolute inset-y-0 right-0 pl-0 sm:pl-10 max-w-full flex"
              >
                <div class="w-screen max-w-none sm:max-w-md">
                  <div
                    class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl"
                  >
                    <div
                      class="min-h-0 flex-1 flex flex-col pb-6 overflow-y-scroll"
                    >
                      <header class="px-4 p-4 bg-gray-100">
                        <div class="flex items-start justify-between space-x-3">
                          <h2
                            v-if="isEdit"
                            class="text-lg leading-7 font-medium text-gray-900"
                          >
                            {{ $t("edit-bank-details") }}
                          </h2>
                          <h2
                            v-else
                            class="text-lg leading-7 font-medium text-gray-900"
                          >
                            {{ $t("add-bank-details") }}
                          </h2>
                          <div
                            class="h-7 flex items-center"
                            @click="
                              hideBankAccount(), (form = {}), (isEdit = false)
                            "
                          >
                            <button
                              aria-label="Close panel"
                              class="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150"
                            >
                              <!-- Heroicon name: x -->
                              <svg
                                class="h-6 w-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6 18L18 6M6 6l12 12"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                        <p class="text-sm font-light text-gray-500">
                          {{
                            $t(
                              "fill-out-the-form-to-add-bank-accounts-for-your-withdrawals"
                            )
                          }}
                        </p>
                      </header>
                      <div class="relative flex-1 px-4 sm:px-6 pt-4">
                        <!-- Replace with your content -->
                        <bank-details-forms
                          :error="error"
                          :fields="get_bank_account_forms"
                          :form="form"
                          :list="true"
                        ></bank-details-forms>
                        <!-- /End replace -->
                      </div>
                    </div>
                    <div
                      class="flex-shrink-0 px-4 py-4 space-x-4 flex justify-end"
                    >
                      <span class="inline-flex rounded-md shadow-sm">
                        <button
                          :disabled="isDisabled"
                          class="relative bg-main-button-color inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-normal rounded-md text-white focus:outline-none focus:shadow-outline-indigo"
                          type="submit"
                        >
                          {{ $t("Submit") }}
                        </button>
                      </span>
                      <span class="inline-flex rounded-md shadow-sm ml-3">
                        <button
                          class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                          type="reset"
                          @click="cancel"
                        >
                          {{ $t("Cancel") }}
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import BankDetailsForms from "@/views/payments/bank-details/BankDetailsForms";
import Header from "@/components/Header";
import BankDetailsCard from "@/views/payments/bank-details/BankDetailsCard";
import NeedTradingAccountAlert from "@/components/NeedTradingAccountAlert";

export default {
  name: "BankDetails",
  components: {
    BankDetailsForms,
    Header,
    BankDetailsCard,
    NeedTradingAccountAlert,
  },
  data() {
    return {
      whitelabel: process.env.VUE_APP_WHITELABEL,
      form: {},
      isEdit: false,
      showModal: false,
      accountToEdit: {},
      editBankAccount: false,
      editedAccount: {},
      isDisabled: false,
      displayNames: [
        "location",
        "account_holdername",
        "currency",
        "bank_name",
        "bank_account_number",
        "iban",
        "swift_code",
        "account_branch",
        "bank_state",
        "bank_city",
        "sort_code",
        "routing_number",
        "email",
      ],
      error: {
        status: false,
        message: "",
      },
    };
  },
  computed: {
    ...mapGetters([
      "get_profile",
      "get_bank_accounts",
      "show_new_bank_account",
      "get_uploaded_documents",
    ]),

    get_bank_account_forms() {
      if (this.$store.getters.get_bank_account_forms.actions) {
        var accounts =
          this.$store.getters.get_bank_account_forms.actions.POST.fields.filter(
            (item) => {
              if (this.displayNames.includes(item.key)) {
                return item;
              }
            }
          );
        const t = this.$t.bind(this);
        if (
          process.env.VUE_APP_WHITELABEL === "ICMTrader" ||
          process.env.VUE_APP_WHITELABEL === "ICMEU" ||
          process.env.VUE_APP_WHITELABEL === "ICMAP"
        ) {
          accounts[6].choices = [
            { display_name: t("GBP"), value: "GBP" },
            { display_name: t("USD"), value: "USD" },
            { display_name: t("EUR"), value: "EUR" },
            { display_name: t("RMB"), value: "RMB" },
            { display_name: t("CHF"), value: "CHF" },
            { display_name: t("JPY"), value: "JPY" },
            { display_name: t("PKR"), value: "PKR" },
            { display_name: t("AED"), value: "AED" },
            { display_name: t("BHD"), value: "BHD" },
            { display_name: t("EGP"), value: "EGP" },
            { display_name: t("JOD"), value: "JOD" },
            { display_name: t("KWD"), value: "KWD" },
            { display_name: t("OMR"), value: "OMR" },
            { display_name: t("QAR"), value: "QAR" },
            { display_name: t("IDR"), value: "IDR" },
          ];
        } else if (process.env.VUE_APP_WHITELABEL === "OneTradingMarkets") {
          accounts[6].choices = [
            { display_name: t("GBP"), value: "GBP" },
            { display_name: t("USD"), value: "USD" },
            { display_name: t("EUR"), value: "EUR" },
            { display_name: t("RMB"), value: "RMB" },
            { display_name: t("CHF"), value: "CHF" },
            { display_name: t("JPY"), value: "JPY" },
          ];
        } else if (process.env.VUE_APP_WHITELABEL === "ICMVC") {
          accounts[6].choices = [
            { display_name: t("GBP"), value: "GBP" },
            { display_name: t("USD"), value: "USD" },
            { display_name: t("EUR"), value: "EUR" },
            { display_name: t("RMB"), value: "RMB" },
            { display_name: t("CHF"), value: "CHF" },
            { display_name: t("JPY"), value: "JPY" },
            { display_name: t("PKR"), value: "PKR" },
            { display_name: t("AED"), value: "AED" },
            { display_name: t("BHD"), value: "BHD" },
            { display_name: t("EGP"), value: "EGP" },
            { display_name: t("JOD"), value: "JOD" },
            { display_name: t("KWD"), value: "KWD" },
            { display_name: t("OMR"), value: "OMR" },
            { display_name: t("QAR"), value: "QAR" },
            { display_name: t("IDR"), value: "IDR" },
            { display_name: t("RON"), value: "RON" },
            { display_name: t("NGN"), value: "NGN" },
            { display_name: t("BDT"), value: "BDT" },
            { display_name: t("MYR"), value: "MYR" },
            { display_name: t("SAR"), value: "SAR" },
            { display_name: t("VND"), value: "VND" },
            { display_name: t("MAD"), value: "MAD" },
            { display_name: t("THB"), value: "THB" },
          ];
        }
        accounts[0].choices = [
          { display_name: t("Europe"), value: 1 },
          { display_name: t("UK"), value: 10 },
          { display_name: t("China"), value: 50 },
          { display_name: t("Rest of the world"), value: 0 },
        ];
        var sorted = accounts.sort((a, b) => {
          return (
            this.displayNames.indexOf(a.key) - this.displayNames.indexOf(b.key)
          );
        });
      }
      return sorted;
    },
  },
  created() {
    this.$store.dispatch("bank_account_forms");
    this.$store.dispatch("bank_accounts");
  },
  methods: {
    addBankAccount() {
      this.isDisabled = true;
      if (this.isEdit) {
        delete this.form.country;
        this.$store
          .dispatch("edit_bank_account", this.form)
          .then(() => {
            this.isDisabled = false;
            this.form = {};
            this.hideBankAccount();
            this.isEdit = false;
            this.$store.dispatch("bank_accounts");
          })
          .catch((err) => {
            this.isDisabled = false;
            if (err.data.swift_code) {
              this.error.status = true;
              this.error.message = err.data.swift_code[0];
            } else {
              this.$notify({
                group: "foo",
                text: `${Object.entries(err.data)[0][1]}`,
                type: "warn",
              });
            }
          });
      } else {
        this.$store
          .dispatch("add_bank_account", this.form)
          .then(() => {
            this.form = {};
            this.hideBankAccount();
            this.isDisabled = false;
            this.error = {
              status: false,
              message: "",
            };
            this.$store.dispatch("bank_accounts");
            this.$notify({
              group: "foo",
              text: `${this.$t("Success!")}`,
            });
          })
          .catch((err) => {
            this.isDisabled = false;
            if (err.data.swift_code) {
              this.error.status = true;
              this.error.message = err.data.swift_code[0];
            } else {
              this.$notify({
                group: "foo",
                text: `${Object.entries(err.data)[0][1]}`,
                type: "warn",
              });
            }
          });
      }
    },
    editBankDetails(account) {
      this.editedAccount = account;
      this.form = Object.keys(account)
        .filter((key) => this.displayNames.includes(key))
        .reduce((obj, key) => {
          obj[key] = account[key];
          return obj;
        }, {});
      this.form.id = account.id;
      this.showBankAccount();
      this.isEdit = true;
    },
    cancel() {
      this.hideBankAccount();
      this.form = {};
      this.isEdit = false;
      this.$store.dispatch("bank_accounts");
    },
    showBankAccount() {
      this.$store.commit("SHOW_ADD_BANK_ACCOUNT", true);
    },
    hideBankAccount() {
      this.$store.commit("SHOW_ADD_BANK_ACCOUNT", false);
    },
  },
};
</script>